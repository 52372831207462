import React from 'react';
import ProductPageTemplate from '../../components/ProductPageTemplate';

import ganzglas_schiebetuer_01 from '../../images/ganzglas_schiebetuer_00_1600.webp'
import ganzglas_schiebetuer_02 from '../../images/ganzglas_schiebetuer_01_1600.webp'
import ganzglas_schiebetuer_03 from '../../images/ganzglas_schiebetuer_02_1600.webp'
import ganzglas_schiebetuer_thumb_01 from '../../images/ganzglas_schiebetuer_00_400.webp'
import ganzglas_schiebetuer_thumb_02 from '../../images/ganzglas_schiebetuer_01_400.webp'
import ganzglas_schiebetuer_thumb_03 from '../../images/ganzglas_schiebetuer_02_400.webp'

const context = {
  title: 'Ganzglas Automatik&#173;schiebetür',
  description: 'Rahmenlos mit schlankem Design, passend in jede Architektur',
  text: 'Eine automatische Schiebetüre besteht aus ein bis zwei sich öffnenden Türflügeln sowie einer Antriebs- und Sensorikeinheit. Sie kann beispielsweise mit einer Festverglasung in ein Mauerwerk als Windfang Innentür eingefasst werden oder als Zugangstür eines representativen Foyer dienen. Die Einbaumaße dieser Automatiktür bewegen sich meist in einer lichten Einbaubreite von 800 mm bis 3000 mm. \n\n' +

  'Die Ganzglas-Automatikschiebetür erlangt Ihre leichte und filigrane Optik durch rahmenlose Schiebeflügel mit offener Glaskante. Dies lässt Ihren Eingangsbereich noch offener wirken und bietet trotzdem alle Vorteile von Barrierefrei, Hygieneschutz, fluchtwegzulassung und zeitgemäßer zu Gestaltung. \n\n' +

  'Nicht von der Stange -  jede Automatiktür ist eine individuell angepasste Lösung. Denn erst wenn das Produkt und die örtlichen Gegebenheiten ineinandergreifen kann eine langfristig zufriedenstellende Eingangssituation entstehen. Um diese nach Ihren Vorstellungen und Wünschen technisch und optisch perfekt zu gestalten, legen wir von Automatiktür24 größten Wert auf eine smarte Beratung und kompetente Umsetzung.',
  features: [
    //'autschr',
    'barrierefreiheit',
    //'brandschutz',
    //'breakOut',
    'design',
    //'din18650',
    //'energieeinsparung',
    //'modernisierung',
    'notStrom',
    'personenfrequenz',
    //'platzsparend',
    'transparenz',
    //'einbruchhemmung',
    'zutrittskontrolle',
    'fluchtweg',
  ],
  legalRequirements: [
    'autschr',
    'din18650',
    //'dinEn170',
    //'eltvtr',
  ],
  carouselImages: [
    {
      original: ganzglas_schiebetuer_01,
      thumbnail: ganzglas_schiebetuer_thumb_01,
      originalAlt: 'Ganzglas Automatikschiebetür geschlossen'
    },
    {
      original: ganzglas_schiebetuer_02,
      thumbnail: ganzglas_schiebetuer_thumb_02,
      originalAlt: 'Ganzglas Automatikschiebetür offen'
    },
    {
      original: ganzglas_schiebetuer_03,
      thumbnail: ganzglas_schiebetuer_thumb_03,
      originalAlt: 'Ganzglas Automatikschiebetür offen'
    },
  ],
  configurator: true,
}

const Page = () => {

  return(
    <ProductPageTemplate pageContext={context} />
  )
}

export default Page;
